html {
  scroll-behavior: smooth;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body .MuiPopover-root {
  z-index: 998!important;
}

:root {
  --fdelay: 15s;
}

p.MuiTypography-root {
  font-size: 1rem;
  margin: 0 0 20px;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.8);
}

.hero h1 {
  text-shadow: 0 1px 10px rgba(0, 0, 0, 0.8);
}

.hero p {
  font-size: 1.2rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 1);
}

.bg-1 {
  background: #000 center url('assets/bg-1.jpg');
  background-size: cover;
}

.section {
  padding: 100px 0;
  color: #fff;
  background: #000;
}

h1, h2, h3, h4, h5, h6 {
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.8);
}

.section h1,
.section h2 {
  color: #47c0e0;
  font-family: Russo One, sans-serif;
}

.section h2 {
  font-size: 2.25em;
  @media screen and (min-width: 640px) {
    font-size: 2.5em;
  }
}

.fade_in {
  opacity: 0;
  transition: opacity 2s;
}
.fade_in--loaded {
  opacity: 1;
}
.float-x {
  animation: float-x var(--fdelay) infinite both;
}
.float-y {
  animation: float-y var(--fdelay) infinite both;
}

@keyframes float-y {
  0%   {transform: translateY(0)}
  25%  {transform: translateY(-5px)}
  75%  {transform: translateY(5px)}
  100% {transform: translateY(0)}
}

@keyframes float-x {
  0%   {transform: translateX(0)}
  25%  {transform: translateX(-5px)}
  75%  {transform: translateX(5px)}
  100% {transform: translateX(0)}
}
